import React, { useEffect } from 'react';
import { CrossStorageHub } from 'cross-storage';
import { useLocalStorage } from 'react-use';
import loadable from '@loadable/component';
import Box from '../components/Box/Box';
import { Body1 } from '../components/Typography/Typography';

const ReactJson = loadable(() => import('react-json-view'));

const $__hub = () => {
    const [hub, setHub] = useLocalStorage('hub', { meetplaces: [] });

    useEffect(() => {
        CrossStorageHub.init([
            {
                origin: new RegExp(process.env.ALLOWED_ORIGINS || '.*'),
                allow: ['get', 'getKeys', 'set', 'del'],
            },
        ]);
    }, []);

    if (
        typeof window !== 'undefined' &&
        process.env.GATSBY_ACTIVE_ENV !== 'production'
    ) {
        return (
            <Box paddingX="5vw" paddingY="5vh">
                <Body1 marginBottom="25px">
                    Allowed Origin: {process.env.ALLOWED_ORIGINS}
                </Body1>
                <Box
                    backgroundColor="neutral.200"
                    borderRadius="25px"
                    p="25px"
                    maxWidth="100%"
                >
                    <ReactJson
                        src={hub}
                        style={{ wordBreak: 'break-word' }}
                        displayDataTypes={false}
                        onAdd={({ updated_src }) => {
                            setHub(updated_src);
                        }}
                        onEdit={({ updated_src }) => {
                            setHub(updated_src);
                        }}
                        onDelete={({ updated_src }) => {
                            setHub(updated_src);
                        }}
                    />
                </Box>
            </Box>
        );
    }
    return null;
};

export default $__hub;
